import React from "react";

import Layout from "../components/Layout";
import AuthContent from "../components/AuthContent";
import ContactForm from "../components/ContactForm";

// markup
const ContactPage = () => {
  return (
    <Layout pageTitle="Contact" pageSlug="contact">
      <AuthContent>
        <ContactForm />
      </AuthContent>
    </Layout>
  );
};

export default ContactPage;
