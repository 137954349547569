import * as React from "react";
import { useMutation, gql } from "@apollo/client";


const SEND_EMAIL = gql`
  mutation SEND_EMAIL($to: String!, $subject: String!, $body: String!  ) {
    sendEmail(
      input: {
        to: $to
        # from: $from
        subject: $subject
        body: $body
      }
    ) {
      origin
      sent
      message
    }
  }
`;


const ContactForm = ()=> {

  const [ sendMail, { loading, data, error } ] = useMutation( SEND_EMAIL );
  const successfullySent = data?.sendEmail?.sent;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>)=> {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const { sendersname, sendersemail, sendersmessage } = Object.fromEntries(data);
    const messageBody = `name: ${sendersname}<br>email: ${sendersemail}<br>message: ${sendersmessage}`;

    // auto reply
    sendMail({
      variables: {
        to: sendersemail,
        // from: 'info@nt-webdesign.net',
        subject: '【自動配信】お問い合わせ誠にありがとうございます',
        body: `${sendersname}様 <br>お問い合わせ誠にありがとうございます。 <br>以下の通りメッセージをお受けいたしました。<br>${messageBody}`,
      }
    }).catch(error => {
      console.error(`error: ${error}`);
    });

    // to admin
    sendMail({
      variables: {
        to: 'info@nt-webdesign.net',
        // from: 'info@nt-webdesign.net',
        subject: '【管理者へ】 コンタクトフォームよりお問い合わせがありました',
        body: `管理者へ <br>コンタクトより以下のとおりお問い合わせがありました。 <br>対応願います。<br>${messageBody}`,
      }
    }).catch(error => {
      console.error(`error: ${error}`);
    });
  }

  if ( !successfullySent ) {
      return (
      <section>
        <p>ご相談等はこちらのお問い合わせフォームをご利用願います。</p>
        <form
          name="contact"
          method="POST"
          className="p-contact-form"
          onSubmit={handleSubmit}
        >
          <fieldset disabled={loading} aria-busy={loading}>
          <dl>
            <div>
              <dt>
                <label htmlFor="sendersname">お名前: </label>
              </dt>
              <dd>
                <input id="sendersname" type="text" name="sendersname" required />
              </dd>
            </div>
            <div>
              <dt>
                <label htmlFor="senderscompany">貴社名: </label>
              </dt>
              <dd>
                <input id="senderscompany" type="text" name="senderscompany" />
              </dd>
            </div>
            <div>
              <dt>
                <label htmlFor="sendersemail">メールアドレス: </label>
              </dt>
              <dd>
                <input id="sendersemail" type="email" name="sendersemail" required />
              </dd>
            </div>
            <div>
              <dt>
                <label htmlFor="sendersmessage">お問い合わせ内容: </label>
              </dt>
              <dd>
                <textarea name="sendersmessage" required></textarea>
              </dd>
            </div>
          </dl>
          <div>
            <div data-netlify-recaptcha="true"></div>
            <button type="submit" className="c-btn--primary" disabled={loading}>
            {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
          </fieldset>
        </form>
      </section>
    )
  } else {
    return <p>お問い合わせ誠にありがとうございます。<br />後ほどご連絡いたしますので、しばらくお待ち下さいませ。</p>
  }
}

export default ContactForm;
